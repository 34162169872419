/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  DashboardLayout,
  OverlayDashboard,
  TopCardEngagement
} from "../CompanyEvents/styles";
import { toast } from "react-toastify";
import {setSelectedYear,} from "../../redux/actions";
import { fetchApi, commaSeperator } from "../../utils/methods";
import YearDropdown from "../common/YearDropdown/YearDropdown";
import { TabContainer, TabView } from "../ChallengeDetailsV2/styles";
import { Container } from "../ChallengeDashboardV2/CompletedChallenge/styles";
import { AdminEventsAddCreateEventButton } from "../../utils/icons";
import { Dropdown } from "react-bootstrap";
import {CardContainer} from './style';
import SkeletonLoder from "../common/skeletonLoder";
import { ImageUrl } from "../../utils/constants";
import { FetchWellnessCards } from "../../redux/constants/apiConstants";
import {MuiltiDropdown} from '../common/commonStyles';

const WellnessAdminMain = (props) => {
  const {
    history,
    yearList,
    selectedYearInfo,
    setSelectedYear,
    role,
    companyInfo
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [WellnessData,setWellnessData]=useState([]);
  const [WellnessDataLoading,setWellnessDataLoading]=useState([]);
  const dropdownRef = useRef();
  const [subMenu, setSubmenu] = useState(0);

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  useEffect(()=>{
    GetWellness(companyInfo?.id,selectedYearInfo);
  },[])

  // const handleClickOutside = (event) => {
  //   if (dropdownRef?.current && !dropdownRef?.current?.contains(event?.target)) {
  //     setMenuIsOpen(false);
  //     setSubmenu(0)
  //   }
  // };

  const handleSubmenu = (value) =>{
    setSubmenu(value)
  }

  const GetWellness = async(companyId, year)=>{
    setWellnessDataLoading(true);
    const apiUrl = FetchWellnessCards + "/" + companyId + "/" + year;
    try{
      const res = await fetchApi(apiUrl,"GET");
      if(res.status === "success"){
        setWellnessDataLoading(false);
        setWellnessData(res?.data);
      }else{
        toast.error(res.error);
        setWellnessDataLoading(false);
      }
    }catch(error){
      window.console.log(error)
    }
  }

  const toggleMenu = () => {
    setMenuIsOpen((prevMenuIsOpen) => !prevMenuIsOpen);
  };

  const updateDropdown = (year) => {
    setSelectedYear(year);
    GetWellness(companyInfo?.id,year);
  };

  const fallBack = (e) => {
    e.target.src = "/public/images/default.png";
  };

  const showEngagementEmployeeDetail = () => {
    const EngagementEmployeeData = [
      {
        title: "Wellness",
        titleNew: "Engagement",
        value: "1",
        img: "/admin-wellness/wellnessE.png",
        text: "per user",
        color: "rgba(175, 135, 193, 1)",
        total: WellnessData[0]?.total_count,
        imageBackground: "rgba(168, 135, 193, 0.15)",
        subText: "Total Wellness Activities",
        average: WellnessData[0]?.average_count + " Activities",
      },
      {
        title: "Wellness Articles",
        titleNew: null,
        value: "2",
        img: "/admin-wellness/wellnessA.png",
        text: "per user",
        color: "rgba(103, 129, 195, 1)",
        total: WellnessData[1]?.total_count,
        average: WellnessData[1]?.average_count + " Articles",
        imageBackground: "rgba(103, 129, 195, 0.15)",
        subText: "Articles Read",
      },
      {
        title: "Fitness Videos",
        titleNew: null,
        value: "3",
        img: "/admin-wellness/fitness.png",
        text: "per user",
        color: "rgba(133, 192, 234, 1)",
        total: WellnessData[2]?.total_count,
        average: WellnessData[2]?.average_count + " Fitness Workouts",
        imageBackground: "rgba(133, 192, 234, 0.15)",
        subText: "Fitness Workouts Watched",
      },
      {
        title: "Recipe Videos",
        titleNew: null,
        value: "4",
        img: "/admin-wellness/recipe.png",
        text: "per user",
        color: "rgba(118, 171, 120, 1)",
        total: WellnessData[3]?.total_count,
        average: WellnessData[3]?.average_count + " Recipes",
        imageBackground: "rgba(118, 171, 120, 0.15)",
        subText: "Recipes Viewed",
      },
      {
        title: "Daily Tips",
        titleNew: null,
        value: "5",
        img: "/admin-wellness/tip.png",
        text: "per user",
        color: "#ffbf73",
        total: WellnessData[4]?.total_count,
        average: WellnessData[4]?.average_count + " Tips",
        imageBackground: "rgba(255, 191, 115, 0.15)",
        subText: "Tips Viewed",
      },
      {
        title: "Article Quizzes",
        titleNew: null,
        value: "6",
        img: "/admin-wellness/articleQuiz.png",
        text: "per user",
        color: "#dc94b5",
        total: WellnessData[5]?.total_count,
        average: WellnessData[5]?.average_count + " Article Quizzes",
        imageBackground: "rgba(220, 148, 181, 0.15)",
        subText: "Article Quizzes Passed",
      },
    ];
    return WellnessDataLoading ? (
      <React.Fragment>
        {" "}
        {EngagementEmployeeData.map((details, index) => (
          <SkeletonLoder
            width={"288px"}
            height={"180px"}
            key={index}
          ></SkeletonLoder>
        ))}
      </React.Fragment>
    ) : (
      EngagementEmployeeData.map((details, index) => (
        <TopCardEngagement
          key={index}
          background={details.imageBackground}
          color={details.color}
          style={{ padding: "12px" }}
        >
          <div className="first-child">
            <div className="image">
              <img
                src={ImageUrl + details.img}
                alt={"engagement-employee"}
                onError={fallBack}
              />
            </div>
            <div className="title">
              {details.title}
              <br />
              {details.titleNew}
            </div>
            <div className="infoIcon">
              {/* {this.infoDetails(details.info)} */}
              {/* <img src={ImageUrl + "/social-feed/info.png"} alt={"engagement-employee"} /> */}
            </div>
          </div>
          <div className="challengeData">
            <span className="value">{commaSeperator(details?.total)}</span>
            <span className="valueText">{details.subText}</span>
          </div>

          <div
            className="borderCard"
            style={{ width: "100%", margin: "20px 0px" }}
          />

          <div className="last-child">
            <span className="participateText">
              {index === 3 ? "Target" : "Average"}
            </span>
            <span className="totalParticipant">{details.average}</span>
            <span className="participateText">{details.text}</span>
          </div>
        </TopCardEngagement>
      ))
    );
  };
  return (
    <Container>
      <div className="mainContainer">
        <OverlayDashboard>
          <DashboardLayout>
            <TabContainer marginBottom="25px">
              <TabView
                width
                padding
                active={1}
                margin={"8px"}
                height="40px"
              >
                {"Wellness"}
              </TabView>
              <MuiltiDropdown>
                <YearDropdown
                  refOutside={dropdownRef}
                  toggleMenu={toggleMenu}
                  background={"white"}
                  title={
                    selectedYearInfo && (
                      <div
                        className="title"
                        style={{
                          display: "flex",
                          justifyContent: yearList?.length > 1 ? "space-between" : "center",
                          width: "100%",
                          fontFamily: "Rubik-Medium",
                          color: "#007AB1",
                          fontSize: "16px",
                          lineHeight: "20px",
                        }}
                      >
                        <div>{selectedYearInfo}</div>
                        {yearList?.length > 1 && (<div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "16px",
                          }}
                        >
                          <svg
                            width="10"
                            height="16"
                            viewBox="0 0 10 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              transform: menuIsOpen ? "rotate(90deg)" : "rotate(90deg)",
                            }}
                          >
                            <path
                              d="M10 8.00003C10 8.28678 9.87995 8.5735 9.64036 8.79212L2.09719 15.6718C1.61736 16.1094 0.839379 16.1094 0.359734 15.6718C-0.119911 15.2343 -0.119911 14.5249 0.359734 14.0872L7.03435 8.00003L0.359967 1.91279C-0.119678 1.47515 -0.119678 0.765811 0.359967 0.328387C0.839612 -0.109461 1.61759 -0.109461 2.09743 0.328387L9.6406 7.20793C9.88023 7.42666 10 7.71338 10 8.00003Z"
                              fill="#007AB1"
                            />
                          </svg>
                        </div>)}
                      </div>
                    )
                  }
                  data={yearList}
                  onSelect={updateDropdown}
                  style={{
                    background: "white",
                    lineHeight: "20px",
                    color: "rgba(0, 92, 135, 0.6)",
                    padding: "0px",
                    border: "1px solid #005C874D",
                    marginRight:"8px",
                    marginTop: "8px",
                    marginBottom: "8px"
                  }}
                />
                {role=="ADMIN"&&<div style={{padding: "0px"}} >
                  <Dropdown >
                    <Dropdown.Toggle>
                      <div style={{background:"rgba(0, 92, 135, 0.05)",padding:"8px",borderRadius:"4px", marginLeft:"8px"}}>{AdminEventsAddCreateEventButton()}</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{width:"240px"}}>
                      <Dropdown.Item
                        onMouseEnter={() => handleSubmenu("article")}
                        onMouseLeave={() => handleSubmenu(false)}
                      >
                        Create Content

                        <svg
                          width="10"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 8.00003C10 8.28678 9.87995 8.5735 9.64036 8.79212L2.09719 15.6718C1.61736 16.1094 0.839379 16.1094 0.359734 15.6718C-0.119911 15.2343 -0.119911 14.5249 0.359734 14.0872L7.03435 8.00003L0.359967 1.91279C-0.119678 1.47515 -0.119678 0.765811 0.359967 0.328387C0.839612 -0.109461 1.61759 -0.109461 2.09743 0.328387L9.6406 7.20793C9.88023 7.42666 10 7.71338 10 8.00003Z"
                            fill="#007AB1"
                          />
                        </svg>
                      </Dropdown.Item>
                      <hr/>
                      <Dropdown.Item
                        onMouseEnter={() => handleSubmenu("fitness")}
                        onMouseLeave={() => handleSubmenu(false)}
                      >
                        Create Category
                        <svg
                          width="10"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 8.00003C10 8.28678 9.87995 8.5735 9.64036 8.79212L2.09719 15.6718C1.61736 16.1094 0.839379 16.1094 0.359734 15.6718C-0.119911 15.2343 -0.119911 14.5249 0.359734 14.0872L7.03435 8.00003L0.359967 1.91279C-0.119678 1.47515 -0.119678 0.765811 0.359967 0.328387C0.839612 -0.109461 1.61759 -0.109461 2.09743 0.328387L9.6406 7.20793C9.88023 7.42666 10 7.71338 10 8.00003Z"
                            fill="#007AB1"
                          />
                        </svg>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                    {subMenu=="article"&&<div className="sub-menu-wrapper" onMouseEnter={() => handleSubmenu("article")} onMouseLeave={() => handleSubmenu(false)}>
                      <div className="menuData">
                        <div className="sub-menu"
                          onClick={() => history.push(`/company/wellness/create-adventure`)}
                        >
                          Add Article
                        </div>
                        <hr/>
                        <div className="sub-menu"
                          onClick={() => history.push(`/company/wellness/create-video`)}
                        >
                          Add Fitness Video
                        </div>
                        <hr/>
                        <div className="sub-menu"
                          onClick={() => history.push(`/company/wellness/create-recipe`)}
                        >
                          Add Recipe Video
                        </div>
                      </div>
                      <div className="leftcard" ></div>
                    </div>}
                    {subMenu=="fitness"&&<div className="sub-menu-wrapper" style={{position:"absolute", top:"100px"}} onMouseEnter={() => handleSubmenu("fitness")} onMouseLeave={() => handleSubmenu(false)}>
                      <div className="menuData">
                        <div className="sub-menu"
                          onClick={() => history.push(`/company/wellness/create-category`)}
                        >
                          Article Category
                        </div>
                        <hr/>
                        <div className="sub-menu"
                          onClick={() => history.push(`/company/wellness/create-video-category`)}
                        >
                          Fitness Category
                        </div>
                        <hr/>
                        <div className="sub-menu"
                          onClick={() => history.push(`/company/wellness/create-recipe-category`)}
                        >
                          Recipe Category
                        </div>
                      </div>
                      <div className="leftcard" ></div>
                    </div>}
                  </Dropdown>
                </div>}
              </MuiltiDropdown>
            </TabContainer>
            <CardContainer>
              {showEngagementEmployeeDetail()}
            </CardContainer>
          </DashboardLayout>
        </OverlayDashboard>
      </div>
    </Container>
  );
};

WellnessAdminMain.defaultProps = {
  companyInfo: { id: 1, name: "" },
};

WellnessAdminMain.propTypes = {
  networkError: PropTypes.string,
  companyInfo: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  history: PropTypes.object,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  setSelectedYear: PropTypes.func,
  user: PropTypes.object,
  t: PropTypes.func,
  userPermissions: PropTypes.array
};

const mapStateToProps = (state) => ({
  networkError: state.companyDashboard.networkError,
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
  user: state.profileData.user,
  role: state.auth.role,
  userPermissions: state.profileData.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedYear: (year) => dispatch(setSelectedYear(year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WellnessAdminMain);
